<template>
  <FloatingContainer v-if="continents">
    <div ref="continentEl" class="continents">
      <button v-for="(value, label) in continentList"
              @click="selectedContinent = value"
              :class="{ selected: selectedContinent === value }"
      >{{ label }}
      </button>
    </div>
  </FloatingContainer>

  <FloatingContainerWithIndicator
    :show="mobile"
    :count="products.length"
    parent=".products"
    child=".itinerary"
    v-slot="{ scroll }"
  >
    <div ref="productListEl" class="products" @scroll="scroll()">
      <ProductCard
        class="itinerary"
        v-for="product in products"
        :key="product.id"
        :product="product"
      />
    </div>
  </FloatingContainerWithIndicator>
</template>

<script setup>
import api from '@/utils/api.js';
import { useShared } from "@/helpers/vueHelpers.js";
import { ref, onMounted, watch, nextTick } from "vue";
import { deserializeJsonApi } from '@/utils/jsonApiDeserializer.js';
import ProductCard from "@/modules/Explore/ProductCard2.vue";
import FloatingContainer from "@/components/Containers/FloatingContainer.vue";
import FloatingContainerWithIndicator from "@/components/Containers/FloatingContainerWithIndicator.vue";

const props = defineProps({
  apiPath: String,
  continents: {
    type: Boolean,
    default: true
  }
});
const { mobile } = useShared();
const continentEl = ref(null);
const productListEl = ref(null);

const products = ref([]);
const continentList = ref({
  'North America': "North America",
  'South America': "South America",
  'Europe': "Europe",
  'Africa': "Africa",
  'Asia': "Asia",
  'Oceania': "Australia",
  // "Antarctica"
});
const selectedContinent = ref('North America');

onMounted(() => {
  loadContinent(selectedContinent.value);
});

watch(selectedContinent, loadContinent);

async function loadContinent(continent) {
  try {
    const url = props.continents ? `${props.apiPath}?continents[]=${continent}` : `${props.apiPath}`;
    const { data } = await api.get(url);
    const allEvents = await deserializeJsonApi(data);
    products.value = allEvents.map(event => {
      const product = { ...event.product };
      product.event = event;
      return product;
    });
    nextTick(() => productListEl.value.scrollLeft = 0);
  } catch (error) {
    console.log(error);
    alert('Failed to load mba itineraries');
  }
}
</script>

<style lang="scss" scoped>
.continents {
  @include hide-scrollbar;

  display: flex;
  justify-content: center;
  overflow: auto;
  gap: 12px;

  @include mobile {
    padding: 0 24px;
    justify-content: flex-start;
  }

  button {
    color: $grey-300;
    padding: 14px 28px;
    border-radius: 56px;
    border: 1px solid $grey-50;
    background: rgba(255, 255, 255, 0.60);
    white-space: nowrap;

    &.selected {
      color: white;
      background-color: $brand-primary;
    }
  }
}

.products {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;

  @include mobile {
    padding: 0 24px;
    display: flex;
    overflow-x: auto;
    gap: 12px;
    scroll-snap-type: x mandatory;

    .itinerary {
      min-width: 88vw;
      flex-shrink: 0;
      scroll-snap-align: center;
    }
  }
}
</style>